<template>
  <div class="view view--protocole--add">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <BtnBack/>
        <h1 class="page-title">
          {{ pageTitle }}
        </h1>
      </div>
    </div>

    <div class="view-body">
      <router-view
        @set-step="setStep($event)"
        @set-route="(event) => {
            this.routeName = event.routeName
            this.previousRouteName = event.previousRouteName
            this.nextRouteName = event.nextRouteName
            this.routeParams = event.routeParams
          }"
      ></router-view>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'protocols'}" />
          <Btn
            v-if="previousRouteName"
            hollow
            :to="{ name: previousRouteName, params: routeParams}"
            text="Précédent"
          />
          <Btn
            :disabled="handleRoute === null"
            btnType="submit"
            :to="handleRoute"
            :text="this.routeName === 'protocolSummary' ? 'Enregistrer' : 'Suivant'"
            @click="this.routeName === 'protocolSummary' ? handleSubmit() : null"
            color="primary"
          />
        </div>
      </div>
    </div>
  </div>

  <NavigationDropdownProtocol />

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import NavigationDropdownProtocol from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'
import BtnBack from '@/components/base/BtnBack.vue'

export default {
  name: 'ProtocolAddEditView',

  components: {
    NavigationDropdownProtocol,
    BtnBack,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      isBpe: false,
      schema: {},
      step: 0,
      routeName: null,
      previousRouteName: 'protocolModalities',
      nextRouteName: null,
      routeParams: {
        id: this.$route.params.id,
      },
      method: 'post',
      protocol: {},
      componentKey: 0,
    }
  },

  computed: {
    handleRoute() {
      if (this.$route.name === 'protocolModalities' && this.$store.state.protocol.modalities.length > 0) {
        return { name: this.nextRouteName, params: this.routeParams }
      }
      if (this.$route.name === 'protocolEntities'
        && (
          typeof this.$store.state.protocol.entities !== 'undefined'
          && this.$store.state.protocol.entities.length > 0
        )
      ) {
        return { name: this.nextRouteName, params: this.routeParams }
      }
      if (this.$route.name === 'protocolSummary') {
        return { name: this.nextRouteName, params: this.routeParams }
      }
      return null
    },
  },

  watch: {
    previousRouteName() {
      this.$nextTick(() => {
        this.componentKey += 1
      })
    },
  },

  created() {
    if (this.$route.params.id) {
      this.method = 'put'
    }
  },

  unmounted() {
    this.$store.dispatch('protocol/selectEntities', [])
    this.$store.dispatch('protocol/selectModalities', [])
  },

  methods: {
    setStep(event) {
      this.step = event
    },

    handleSubmit() {
      this.emitter.emit('open-loader')
      if (this.$route.name === 'protocolSummary') {
        const { modalities } = this.$store.state.protocol
        const { entities } = this.$store.state.protocol

        const data = []
        modalities.forEach((modalite) => {
          data.push({
            id: modalite,
          })
        })

        entities.forEach((entite) => {
          this.emitter.emit('open-loader')
          this.fetchService.patch(`protocole/${this.$route.params.id}/partager`, {
            modalites: data,
            entite_id: entite.id,
          }).then(() => {
            this.$store.dispatch('protocol/selectModalities', [])
            this.$store.dispatch('protocol/selectEntities', [])

            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le protocole a bien été partagé.',
            })

            this.emitter.emit('close-loader')

            this.$router.push({
              name: 'protocolShared',
              params: {
                id: this.$route.params.id,
              },
            })
          },
          (error) => {
            this.emitter.emit('close-loader')
            console.log(error)
          })
        })
      }
    },

    sanitizeValues(values) {
      if (Object.keys(values).length) {
        Object.keys(values).forEach((key) => {
          if (values[key] === null || typeof (values[key]) === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            delete values[key]
          }
        })
      }
      return values
    },
  },
}
</script>
